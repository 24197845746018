<template>
    <div>
        <Header :instanceInfo="instanceInfo" />
        <div class="container">
            <div class="col-md-12">
                <h2 class="pink p-block">
                    Protecting our children!
                </h2>
            </div>
            <div class="col-md-12 p-block">

                <p>
                    In our ongoing efforts to keep explicit content away from underaged persons, we made this document to
                    help parents understand how to protect their children from inappropriate/adult online content.
                </p>
                <p>
                    The first step to protect your children is to install parental control software on your computer. Some
                    of the best-known and respected parental control software packages are CyberPatrol, Net Nanny and Safety
                    Surf.
                </p>

                <h3>How does Parental control software work?</h3>

                <p>
                    Parental control software works by blocking access to specific websites and online content. In most
                    cases, when you buy the software it already contains a large list of sites that are inappropriate for
                    children. You can then update the software through the manufacturer's website or, in some cases, the
                    software will update itself.
                </p>
                <p>
                    Another way filtering software works is to block sites based on key words, such as "vagina, penis or
                    sex." However, filtering software doesn't have to just block access to adult-oriented material. You can
                    configure the software to filter for topics such as tobacco, drugs and drug culture, alcohol, violence
                    and racism. Most households have more than one computer or device to surf on the Internet. Please
                    install the software on all devices on which it is possible to surf on the Internet. Secure all devices
                    and software with a password.
                </p>

                <h3>No guarantee to exposure of inappropriate content</h3>

                <p>
                    Installing filtering software is no guarantee that children won't be exposed to some inappropriate
                    material online. New websites pop up everyday, so it's important to keep your software up to date. Also,
                    learn how your Internet browser software works so that you can check the sites your family has visited
                    (this is the "History" feature which displays a list of visited through the web browser).
                </p>
                <p>
                    Talking to your children about the possible dangers that exist on the Internet, and supervising their
                    web surfing are still the best ways to protect them.
                </p>

                <h3>Talk with your children about the following:</h3>

                <ul>
                    <li>Don't tell anything about yourself or family to strangers you meet online.</li>
                    <li>People on the Internet can easily pretend to be someone else and could in fact be predators
                        searching to abuse children.</li>
                    <li>Teach them that they should never meet a new online friend in person without an adult they know
                        being present.</li>
                    <li>Never send pictures to strangers online and if they get a nude picture of someone to inform you
                        immediately.</li>
                </ul>

                <p>
                    <strong>To purchase parental control software, please visit:</strong><br>
                    <a rel="nofollow" href="http://www.cyberpatrol.com" target="_blank">www.cyberpatrol.com</a> or <a
                        rel="nofollow" href="http://www.safetysurf.com" target="_blank">www.safetysurf.com</a>
                </p>

            </div>
        </div>
        <Footer :instance-info="instanceInfo" :company-name="companyName" v-bind="$attrs"/>
    </div>
</template>

<script>
import Header from '@/app/components/Header';
import Footer from '@/app/components/Footer';

export default {
    name: 'ChildrenProtection',
    components: { Header, Footer },
    props: {
        instanceInfo: {
            type: Object,
            required: false,
        },
        companyName: {
          type: String,
          required: false,
        },
    },
};
</script>
